import dateFormat from "dateformat";
import APP_CONSTANTS from "../constants";

const { DATE_AND_TIME, TYPE, ACTIVITY, EVENT_WEBPAGE } = APP_CONSTANTS;

const EventCard = ({ event }) => {
  return (
    <div className=" space-y-6 mx-3 mt-6 pb-12">
      <h3 className="text-white font-medium font-display">
        {dateFormat(event[DATE_AND_TIME], "d mmm, yyyy")}
      </h3>
      {event[TYPE]?.map((type, index) => (
        <h3
          key={`${event.id}${index}`}
          className="text-white font-display font-medium"
        >
          {type}
        </h3>
      ))}
      <h2 className="text-white text-4xl font-display font-bold">
        {event[ACTIVITY]}
      </h2>
      {event.topics.length > 0 && !event[EVENT_WEBPAGE] && (
        <h3 className="text-white font-display font-medium">
          Topics: {[...event.topics].splice(0, 6).join(", ")}{" "}
          {event.topics.length > 6
            ? `and ${[...event.topics].splice(5).length} more`
            : ""}
        </h3>
      )}
      {event.location && (
        <h3 className="text-white font-medium font-display">
          {Object.values(event.location).join(", ")}
        </h3>
      )}
    </div>
  );
};

export default EventCard;
