const APP_CONSTANTS = {
  SUBSCRIPTION_ACTIVE: "active",
  SUBSCRIPTION_INACTIVE: "inactive",
  SUBSCRIPTION_BILLED: "billed",
  SUBSCRIPTION_FREE: "free",
  DATE_AND_TIME: "Date & Time",
  SET_LIBRARY: "SET_LIBRARY",
  RESET_LIBRARY: "RESET_LIBRARY",
  SET_SEARCH_TERM: "SET_SEARCH_TERM",
  SET_START_DATE: "SET_START_DATE",
  SET_END_DATE: "SET_END_DATE",
  SPONSOR_TOPIC: "Sponsor Topic",
  COMPANY_INFO: "Company Info",
  VIDEO_LINK: "Video Link",
  REGISTRATION_LINK: "Online Registration Link",
  TYPE: "Type",
  ACTIVITY: "Activity",
  EVENT_WEBPAGE: "Event Webpage",
  IN_PERSON_AND_VIRTUAL_REGISTRATION: "In Person & Virtual Registration",
};

export default APP_CONSTANTS;
