import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import UserContext from "../context/UserContext";

const Checkout = () => {
  const { token } = useContext(UserContext);
  const { loading: loadingAuth } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);

  useEffect(() => {
    const checkSubscription = async () => {
      const {
        data: { status },
      } = await axios({
        method: "GET",
        url: "/api/subscription/check",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (status === "active") {
        return setRedirect("/");
      }
      setLoading(false);
    };
    if (token) {
      checkSubscription();
    }
  }, [token]);

  useEffect(() => {
    const getSubscriptionOptions = async () => {
      const { data } = await axios({
        method: "GET",
        url: "/api/subscription/options",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSubscriptionOptions(data);
    };
    if (token) getSubscriptionOptions();
  }, [token]);

  const handleCheckout = async (priceId) => {
    const { data } = await axios({
      method: "post",
      url: "/api/checkout",
      data: {
        priceId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    window.location.href = data.url;
  };

  return redirect ? (
    <Redirect to={redirect} />
  ) : loading || loadingAuth ? (
    <div className="h-96">
      <p>Loading...</p>
    </div>
  ) : (
    <div className="flex content-start justify-center flex-wrap h-96">
      <h2 className="h-1 mt-6 w-full text-center font-body text-xl">
        Your subscription is not currently active. Please choose from a
        subscription below
      </h2>
      <form className="w-full mt-12 max-w-sm">
        {subscriptionOptions.length > 0 &&
          subscriptionOptions.map(({ id, metadata: { type } }) => (
            <button
              className="border-transparent w-full p-4 mt-2 bg-gfpoBlue text-white border-4 font-display text-sm py-1 px-2 rounded"
              type="button"
              onClick={() => handleCheckout(id)}
              key={id}
            >
              {type} subscription
            </button>
          ))}
      </form>
    </div>
  );
};

export default Checkout;
