import axios from "axios";
import logo from "../assets/logo.png";
import AuthenticationButton from "./AuthenticationButton";
import { Link } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../context/UserContext";
import APP_CONSTANTS from "../constants";
const { SUBSCRIPTION_FREE } = APP_CONSTANTS;

const Navbar = () => {
  const { token, type } = useContext(UserContext);

  const handleManageSubscription = async () => {
    const { data } = await axios({
      method: "PUT",
      url: "/api/subscription/session",
      data: {},
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    window.location.href = data.url;
  };

  return (
    <div className="bg-gfpoBlue">
      <Link className="flex justify-center h-48 pt-7" to="/">
        <img src={logo} alt="Global Partnership Family Offices Logo" />
      </Link>
      <div className="flex justify-center space-x-8 mt-20 pb-8">
        <Link
          to="/library"
          className="text-sm text-white font-display hover:text-gfpoRed"
        >
          Library
        </Link>
        <Link
          to="/upcoming-forums"
          className="text-sm text-white font-display hover:text-gfpoRed"
        >
          Upcoming Forums
        </Link>
        {type !== SUBSCRIPTION_FREE && (
          <button
            onClick={() => handleManageSubscription()}
            className="text-sm text-white font-display hover:text-gfpoRed"
          >
            Manage Subscription
          </button>
        )}
        <AuthenticationButton />
      </div>
    </div>
  );
};

export default Navbar;
