import { Link } from "react-router-dom";

const Error = ({
  match: {
    params: { errorType },
  },
}) => {
  const errorTypeMap = {
    metadata: `No user metadata. Please contact `,
  };

  const error = errorType
    ? errorTypeMap[errorType]
    : `Something went wrong. If this problem persists, please contact `;

  return (
    <div className="flex justify-center flex-wrap h-96">
      <h1 className="mt-6 w-full text-center font-body text-xl">
        {error}
        <a href="mailto:bp@gpfo.co.uk">bp@gpfo.co.uk</a> and reference this
        message.{" "}
        <Link to="/" className="underline">
          Click here to return to home page
        </Link>
      </h1>
    </div>
  );
};

export default Error;
