import HomePageCard from "./HomePageCard";
import { BookOpenIcon, CalendarIcon } from "@heroicons/react/outline";
import { FilterIcon } from "@heroicons/react/solid";

const Home = () => (
  <div className="container mx-auto">
    <h1 className="my-16 text-center font-display text-4xl font-medium">
      Members Homepage
    </h1>
    <div className="grid sm:grid-cols-1 lg:grid-cols-3 mb-12 lg:mx-36">
      <HomePageCard
        Icon={BookOpenIcon}
        heading="Library"
        subheading="The Archive of previous insights, topics and briefings."
        link={"/library"}
        linkText="Explore"
        bgColor="white"
        subheadingColor="gray-400"
      />
      <HomePageCard
        Icon={FilterIcon}
        heading="Deal Screen"
        subheading="Coming soon... See what our team sees, explore all screened opportunities. Even those that do not present."
        anchor={"mailto:bp@gpfo.co.uk"}
        linkText="Register Interest"
        bgColor="gray-200"
        subheadingColor="black"
      />
      <HomePageCard
        Icon={CalendarIcon}
        heading="Upcoming Forums"
        subheading="Register for upcoming forums."
        link={"/upcoming-forums"}
        linkText="View"
        bgColor="white"
        subheadingColor="gray-400"
      />
    </div>
  </div>
);

export default Home;
