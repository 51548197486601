import { Fragment, useEffect, useState, useReducer, useContext } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import getBackgroundImage from "../helpers/getBackgroundImage";
import LoadingSkeleton from "./LoadingSkeleton";
import EventCard from "./EventCard";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import dateFormat from "dateformat";
import getErrorRedirect from "../helpers/getErrorRedirect";
import Dropdown from "./Dropdown";
import UserContext from "../context/UserContext";
import APP_CONSTANTS from "../constants";

const {
  DATE_AND_TIME,
  SET_LIBRARY,
  RESET_LIBRARY,
  SET_SEARCH_TERM,
  SET_START_DATE,
  SET_END_DATE,
  EVENT_WEBPAGE,
} = APP_CONSTANTS;

export const SET_CHECKBOX = "SET_CHECKBOX";

const filterBySearchTerm = ({ library, filters }) => {
  if (library.length === 0) return { library, filters };

  const { searchTerm } = filters;
  const updatedLibrary =
    searchTerm.length > 0
      ? library.filter((event) => {
          const filterFields = [
            event.Activity,
            event.location ? Object.values(event.location).join(" ") : "",
            event.topics.join(" "),
          ].filter((x) => x);

          const regExSearchTerm = new RegExp(
            searchTerm.trim().split(" ").join("|")
          );
          return filterFields.some((field) =>
            field.toLowerCase().match(regExSearchTerm)
          );
        })
      : library;
  return { library: updatedLibrary, filters };
};

const filterByDate = ({ library, filters }) => {
  if (library.length === 0) return { library, filters };
  const { startDate, endDate } = filters;
  const filterStartDate =
    new Date(startDate).valueOf() ||
    new Date(library[library.length - 1][DATE_AND_TIME]).valueOf();
  const filterEndDate = new Date(endDate)
    .setDate(endDate.getDate() + 1)
    .valueOf();

  const updatedLibrary = library.filter((event) => {
    const eventDate = new Date(event[DATE_AND_TIME]).valueOf();
    return eventDate >= filterStartDate && eventDate <= filterEndDate;
  });

  return { library: updatedLibrary, filters };
};

const filterByCheckbox = ({ library, filters }) => {
  if (library.length === 0) return { library, filters };
  const { eventKeys, options } = filters;

  const allResults = eventKeys.map((eventKey) => {
    return library.filter((event) =>
      options.some((option) => event?.[eventKey]?.includes(option))
    );
  });

  const numberOfResults = allResults.length;
  const mergedResults = allResults.reduce(
    (acc, results) => [...acc, ...results],
    []
  );

  const updatedLibrary = mergedResults.reduce((acc, result) => {
    const numberOfResult = mergedResults.filter(
      ({ id }) => id === result.id
    ).length;
    return numberOfResult === numberOfResults &&
      !acc.some(({ id }) => id === result.id)
      ? [...acc, result]
      : acc;
  }, []);

  return { library: updatedLibrary, filters };
};

const initialFilterLibraryState = {
  filteredLibrary: [],
  initialLibrary: [],
  filters: {
    searchTerm: "",
    startDate: null,
    endDate: new Date(),
    eventKeys: [],
    options: [],
    eventKeyOptions: {},
  },
};

const filteredLibraryReducer = (state, { type, payload }) => {
  if (type === SET_LIBRARY) {
    const { library } = payload;
    return { ...state, initialLibrary: library, filteredLibrary: library };
  }

  if (type === RESET_LIBRARY) {
    const { library } = payload;
    return {
      ...initialFilterLibraryState,
      initialLibrary: library,
      filteredLibrary: library,
    };
  }

  if (type === SET_SEARCH_TERM) {
    const { filters, initialLibrary } = state;
    const { value } = payload;
    const updatedFilters = { ...filters, searchTerm: value };
    const { library } = filterByCheckbox(
      filterByDate(
        filterBySearchTerm({
          library: initialLibrary,
          filters: updatedFilters,
        })
      )
    );
    return {
      ...state,
      filteredLibrary: library,
      filters: updatedFilters,
    };
  }

  if (type === SET_START_DATE) {
    const { filters, initialLibrary } = state;
    const { startDate } = payload;

    const updatedFilters = { ...filters, startDate };
    const { library } = filterByCheckbox(
      filterByDate(
        filterBySearchTerm({
          library: initialLibrary,
          filters: updatedFilters,
        })
      )
    );
    return {
      ...state,
      filteredLibrary: library,
      filters: updatedFilters,
    };
  }

  if (type === SET_END_DATE) {
    const { filters, initialLibrary } = state;
    const { endDate } = payload;

    const updatedFilters = { ...filters, endDate };
    const { library } = filterByCheckbox(
      filterByDate(
        filterBySearchTerm({
          library: initialLibrary,
          filters: updatedFilters,
        })
      )
    );
    return {
      ...state,
      filteredLibrary: library,
      filters: updatedFilters,
    };
  }

  if (type === SET_CHECKBOX) {
    const { filters, initialLibrary } = state;
    const { options, eventKey } = payload;

    const updatedEventKeyOptions = {
      ...filters.eventKeyOptions,
      [eventKey]: options,
    };

    const updatedFilters = {
      ...filters,

      options: Object.values(updatedEventKeyOptions).reduce(
        (acc, option) => [...acc, ...option],
        []
      ),
      eventKeyOptions: updatedEventKeyOptions,
      eventKeys: [
        ...filters.eventKeys.filter((key) => key !== eventKey),
        eventKey,
      ],
    };

    const { library } = filterByCheckbox(
      filterByDate(
        filterBySearchTerm({
          library: initialLibrary,
          filters: updatedFilters,
        })
      )
    );
    return {
      ...state,
      filteredLibrary: library,
      filters: updatedFilters,
    };
  }

  return state;
};

const Library = () => {
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [library, setLibrary] = useState([]);
  const [
    {
      filteredLibrary,
      filters: { startDate, endDate, searchTerm },
    },
    filteredLibraryDispatch,
  ] = useReducer(filteredLibraryReducer, initialFilterLibraryState);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    const fetchLibrary = async () => {
      try {
        const {
          data: { library },
        } = await axios.get("/api/library", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLibrary(library);
        filteredLibraryDispatch({ type: SET_LIBRARY, payload: { library } });
        setLoading(false);
      } catch ({ response }) {
        const errorRedirect = getErrorRedirect(response);
        setRedirect(errorRedirect);
      }
    };
    if (token) {
      fetchLibrary();
    }
  }, [token]);

  const handleFilter = ({ target: { value } }) => {
    filteredLibraryDispatch({ type: SET_SEARCH_TERM, payload: { value } });
  };

  const handleSelectStartDate = (date) => {
    filteredLibraryDispatch({
      type: SET_START_DATE,
      payload: { startDate: date },
    });
  };

  const handleSelectEndDate = (date) => {
    filteredLibraryDispatch({
      type: SET_END_DATE,
      payload: { endDate: date },
    });
  };

  const getDropdownOptionsFor = (eventKey) => [
    ...new Set(
      library
        .filter((event) => event?.[eventKey]?.length > 0)
        .map((event) => event?.[eventKey]?.[0])
    ),
  ];

  return redirect.length ? (
    <Redirect to={redirect} />
  ) : loading ? (
    <div className="m-2 flex flex-wrap justify-center">
      {Array(3)
        .fill({})
        .map((_, index) => (
          <Fragment key={index}>
            <LoadingSkeleton />
          </Fragment>
        ))}
    </div>
  ) : (
    <div className="container mx-auto mb-8">
      <form className="flex flex-wrap lg:flex-nowrap w-full justify-center space-x-2 lg:space-x-4">
        <div className="w-full md:w-1/3 lg:my-6 ml-2">
          <label className="font-display mr-2">Search Library: </label>
          <input
            className="rounded font-display block border border-gray-300"
            onChange={handleFilter}
            value={searchTerm}
            placeholder="Enter text"
          />
        </div>
        <div className="w-full lg:w-1/2 my-2 lg:my-6 lg:mr-8">
          <label className="font-display mr-2">Filter by Pillar: </label>
          <Dropdown
            dropdownOptions={getDropdownOptionsFor("Pillar")}
            eventKey={"Pillar"}
            filteredLibraryDispatch={filteredLibraryDispatch}
          />
        </div>
        <div className="w-full lg:w-1/2 my-2 lg:my-6 lg:mr-8">
          <label className="font-display mr-2">Filter by Type: </label>
          <Dropdown
            dropdownOptions={getDropdownOptionsFor("Type")}
            eventKey={"Type"}
            filteredLibraryDispatch={filteredLibraryDispatch}
          />
        </div>
        <div className="flex w-full justify-end">
          <div className="w-1/3 my-2 lg:my-6 mr-2">
            <label className="mt-6 font-display mr-2">Start Date: </label>
            <DatePicker
              className="rounded font-display cursor-pointer border border-gray-300 w-full"
              placeholderText="Start date"
              onSelect={handleSelectStartDate}
              value={startDate ? dateFormat(startDate, "d mmm, yyyy") : null}
              maxDate={endDate || new Date()}
              showMonthDropdown={true}
              showYearDropdown={true}
            />
          </div>
          <div className="w-1/3 my-2 lg:my-6">
            <label className="mt-6 font-display mr-2">End Date: </label>
            <DatePicker
              className="rounded font-display cursor-pointer border border-gray-300 w-full"
              placeholderText="Select end date"
              minDate={startDate}
              onSelect={handleSelectEndDate}
              maxDate={new Date()}
              value={endDate ? dateFormat(endDate, "d mmm, yyyy") : null}
              showMonthDropdown={true}
              showYearDropdown={true}
            />
          </div>
        </div>
        <div className="w-full lg:w-1/3 my-2 lg:my-6">
          <button
            onClick={(e) => {
              e.preventDefault();
              filteredLibraryDispatch({
                type: RESET_LIBRARY,
                payload: { library },
              });
            }}
            className="mt-4 bg-white font-display mr-2 rounded font-display px-4 py-2 border border-gray-300"
          >
            Reset
          </button>
        </div>
      </form>
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-3 auto-rows-fr">
        {filteredLibrary.length > 0 ? (
          filteredLibrary.map((event, index) =>
            event[EVENT_WEBPAGE] ? (
              <a
                key={`${event.id}_${index}`}
                href={event[EVENT_WEBPAGE]}
                target="_blank"
                rel="noreferrer"
                className={`bg-${getBackgroundImage(
                  event.Type
                )} bg-cover bg-no-repeat m-2 p-2 flex`}
              >
                {" "}
                <EventCard event={event} />
              </a>
            ) : (
              <Link
                key={`${event.id}_${index}`}
                to={`/library/${event.id}`}
                className={`bg-${getBackgroundImage(
                  event.Type
                )} bg-cover bg-no-repeat m-2 p-2 flex`}
              >
                <EventCard event={event} />
              </Link>
            )
          )
        ) : (
          <p className="mt-6 w-full text-center font-body text-xl">
            No events found
          </p>
        )}
      </div>
    </div>
  );
};

export default Library;
