import { useState, useReducer, useEffect } from "react";
import { SET_CHECKBOX } from "./Library";

const UPDATE_CHECKBOX = "UPDATE_CHECKBOX";
const SELECT_ALL = "selectAll";

const checkedOptionsReducer = (state, { type, payload }) => {
  if (type === SELECT_ALL) {
    const { selectAll } = state;
    return Object.keys(state).reduce(
      (acc, key) => ({ ...acc, [key]: !selectAll }),
      {}
    );
  }
  if (type === UPDATE_CHECKBOX) {
    return { ...state, [payload]: !state[payload] };
  }
  return state;
};

const Dropdown = ({ dropdownOptions, filteredLibraryDispatch, eventKey }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [checkedOptions, checkedOptionsDispatch] = useReducer(
    checkedOptionsReducer,
    dropdownOptions.reduce((acc, option) => ({ ...acc, [option]: true }), {
      selectAll: true,
    })
  );

  const handleCheckboxChange = (option) => {
    checkedOptionsDispatch({
      type: UPDATE_CHECKBOX,
      payload: option,
    });
  };

  useEffect(() => {
    const options = Object.entries(checkedOptions).reduce(
      (acc, [optionKey, optionValue]) =>
        optionValue ? [...acc, optionKey] : acc,
      []
    );

    filteredLibraryDispatch({
      type: SET_CHECKBOX,
      payload: { eventKey, options },
    });
  }, [checkedOptions, eventKey, filteredLibraryDispatch]);

  return (
    <div className="relative block text-left">
      <div onClick={() => setShowDropdown(!showDropdown)}>
        <button
          type="button"
          className="inline-flex w-full rounded font-display block border border-gray-300 shadow-sm px-4 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          Options
          <svg
            className={`-mr-1 ml-2 h-5 w-5 ${
              showDropdown ? "" : "transform rotate-180"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {showDropdown && (
        <div
          className="origin-top-right absolute right-0 py-3 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div role="none">
            <label className="inline-flex items-center mt-3">
              <input
                type="checkbox"
                className="form-checkbox  text-gray-600 text-gray-700 mx-4 block text-sm"
                checked={checkedOptions.selectAll}
                onChange={() =>
                  checkedOptionsDispatch({
                    type: SELECT_ALL,
                  })
                }
              />
              <span className="font-display ml-2 text-gray-700">
                {checkedOptions.selectAll ? `Unselect all` : `Select all`}
              </span>
            </label>
            {dropdownOptions.map((option, index) => (
              <label
                key={`${option}-${index}`}
                className="inline-flex items-center mt-3"
              >
                <input
                  type="checkbox"
                  className="form-checkbox  text-gray-600 text-gray-700 mx-4 block text-sm"
                  checked={checkedOptions[option]}
                  onChange={() => handleCheckboxChange(option)}
                />
                <span className="font-display ml-2 text-gray-700">
                  {option}
                </span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
