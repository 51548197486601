/* eslint-disable no-useless-computed-key */

const imageMap = {
  ["Investment Club"]: "investmentClub",
  ["Conference"]: "conferenceTile",
  ["Social Event"]: "socialTile",
  ["International Investment Afternoon"]: "internationalInvestmentAfternoon",
  ["Private Briefing"]: "privateBriefing",
  ["Family Office Solutions Series"]: "familyOfficeSolutionSeries",
  ["Virtual Forum"]: "virtualForum",
  ["Coffee With..."]: "coffeeWithTile",
  ["Publication"]: "publicationTile",
};

const getBackgroundImage = (type) => {
  return imageMap[type] || imageMap["Virtual Forum"];
};

export default getBackgroundImage;
