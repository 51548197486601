import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaEnvelope,
} from "react-icons/fa";

const SocialMediaIcon = ({ href, Icon }) => (
  <a
    className="text-white text-xl text-semibold"
    href={href}
    rel="noreferrer"
    target="_blank"
  >
    <Icon />
  </a>
);

const QuickLink = ({ href, body }) => (
  <a
    className="font-body text-white text-sm w-full"
    href={href}
    rel="noreferrer"
    target="_blank"
  >
    {body}
  </a>
);

const Footer = () => (
  <div className="w-full h-96 grid grid-rows-9 gap-0">
    <div className="bg-footerBlack w-full row-span-2">
      <div className="container mx-auto mt-12 mb-6 lg:mb-0">
        <div className="flex justify-center lg:space-x-12 lg:flex-no-wrap flex-wrap">
          <div className="w-1/2 lg:w-1/5 mx-2">
            <h2 className="font-display font-bold text-white">Follow Us</h2>
            <p className="font-body text-white text-sm my-6">
              Stay in touch with us on social media and find out more about what
              we do.
            </p>
            <div className="flex space-x-6">
              <SocialMediaIcon
                Icon={FaLinkedinIn}
                href="https://www.linkedin.com/company/global-partnership-family-offices"
              />
              <SocialMediaIcon
                Icon={FaTwitter}
                href="https://twitter.com/gpfoffices"
              />
              <SocialMediaIcon
                Icon={FaInstagram}
                href="http://instagram.com/gpfoffices"
              />
              <SocialMediaIcon
                Icon={FaEnvelope}
                href="mailto:info@gpfo.co.uk"
              />
            </div>
          </div>
          <div className="w-1/2 lg:w-1/5 mx-2 my-6 lg:my-0">
            <h2 className="font-display font-bold text-white mb-6">
              Quick Links
            </h2>
            <div className="flex flex-wrap space-y-3">
              <QuickLink
                href="https://www.gpfo.co.uk/membership"
                body="Membership"
              />
              <QuickLink
                href="https://www.gpfo.co.uk/family-offices"
                body="For Family Offices"
              />
              <QuickLink
                href="https://www.gpfo.co.uk/service-providers"
                body="For Service Providers"
              />
              <QuickLink
                href="https://www.gpfo.co.uk/upcoming-forums"
                body="Upcoming Forums"
              />
              <QuickLink
                href="https://www.gpfo.co.uk/gpfo-100"
                body="GPFO 100"
              />
            </div>
          </div>
          <div className="w-1/2 lg:w-1/5 mx-2 ">
            <h2 className="font-display font-bold text-white mb-6">
              Newsletter
            </h2>
            <p className="font-body text-white text-sm mt-6 mb-8">
              Keep your finger on the pulse of the family office community
            </p>
            <a
              className="transition duration-250 ease-in-out py-4 px-14 bg-white hover:bg-transparent hover:text-white font-body rounded font-bold text-lg"
              href="https://www.gpfo.co.uk/newsletter"
              rel="noreferrer"
              target="_blank"
            >
              Sign Up Now
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-gfpoBlue w-full row-span-2 pb-2">
      <div className="container mx-4 lg:mx-auto">
        <div className="flex justify-center space-x-2 lg:space-x-80">
          <p className="font-body text-white text-sm mt-8">
            London | Melbourne | Dubai | Zurich | Hong Kong | Jersey | India
          </p>
          <p className="font-body text-white text-sm mt-8">
            Copyright © All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
    <div className="bg-gfpoRed w-full row-span-1"></div>
  </div>
);

export default Footer;
