import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import UserContext from "../context/UserContext";
const SEARCH_QUERY = "?session_id=";
const Subscription = () => {
  const { token } = useContext(UserContext);
  const { search } = useLocation();
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    const completeSubscription = async () => {
      const sessionId = search.replace(SEARCH_QUERY, "");
      await axios({
        method: "post",
        url: "/api/subscription/complete",
        data: {
          sessionId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setRedirect("/");
    };

    if (token) {
      completeSubscription();
    }
  }, [token, search]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <div className="h-96">
      <p>Completing subscription...</p>
    </div>
  );
};

export default Subscription;
