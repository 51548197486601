const getErrorRedirect = (response) => {
  let redirect = "";

  if (response.status === 401) {
    redirect = "/";
  }
  if (response.status === 404) {
    if (response.data.error.type === "no_subscription") {
      redirect = "/subscription";
    }

    if (response.data.error.type === "no_metadata") {
      redirect = "/error/metadata";
    }
  } else {
    redirect = "/error";
  }

  return redirect;
};

export default getErrorRedirect;
