import { Link } from "react-router-dom";

const HomePageCard = ({
  Icon,
  heading,
  subheading,
  link,
  linkText,
  anchor,
  bgColor,
  subheadingColor,
}) => (
  <div
    className={`shadow-2xl mx-2 flex justify-center flex-wrap space-y-6 py-16 px-4 bg-${bgColor}`}
  >
    <Icon className="h-10 w-10 text-blue-500 " />
    <h2 className="w-full font-display text-xl font-bold tracking-wide text-center">
      {heading}
    </h2>
    <h3
      className={`w-full font-body text-${subheadingColor} text-center text-sm`}
    >
      {subheading}
    </h3>
    {anchor ? (
      <a
        href={anchor}
        target="_blank"
        rel="noreferrer"
        className="font-body text-blue-600 font-bold"
      >
        {linkText}
      </a>
    ) : (
      <Link className="font-body text-blue-600 font-bold" to={link}>
        {linkText}
      </Link>
    )}
  </div>
);

export default HomePageCard;
