const imageMap = {
  "Investment Club": "investmentClubBanner",
  Conference: "conferenceBanner",
  "Social Event": "socialBanner",
  "International Investment Afternoon":
    "internationalInvestmentAfternoonBanner",
  "Private Briefing": "privateBriefingBanner",
  "Family Office Solutions Series": "familyOfficeSolutionSeriesBanner",
  "Virtual Forum": "virtualForumBanner",
  "Coffee With...": "coffeeWithBanner",
  "Publication": "publicationBanner",
};

const getBackgroundBannerImage = (type) => {
  return imageMap[type] || imageMap["Virtual Forum"];
};

export default getBackgroundBannerImage;
