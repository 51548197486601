import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useContext } from "react";
import { Redirect, useParams } from "react-router-dom";
import dateFormat from "dateformat";
import getBackgroundBannerImage from "../helpers/getBackgroundBannerImage";
import { DownloadIcon, PlayIcon } from "@heroicons/react/solid";
import LoadingSkeleton from "./LoadingSkeleton";
import ReactPlayer from "react-player";
import getErrorRedirect from "../helpers/getErrorRedirect";
import UserContext from "../context/UserContext";
import APP_CONSTANTS from "../constants";

const { DATE_AND_TIME, SPONSOR_TOPIC, COMPANY_INFO, VIDEO_LINK } =
  APP_CONSTANTS;

const Event = ({ id }) => {
  const { token } = useContext(UserContext);
  const [redirect, setRedirect] = useState("");
  const [eventId, setEventId] = useState(id);
  const [event, setEvent] = useState(null);
  const { id: paramsId } = useParams();
  const { loading: loadingAuth } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [playingVideo, setPlayingVideo] = useState({});

  useEffect(() => {
    if (!id) {
      setEventId(paramsId);
    }
  }, [id, paramsId]);

  useEffect(() => {
    const getEvent = async () => {
      const {
        data: { event },
      } = await axios({
        method: "GET",
        url: `/api/library/${eventId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEvent(event);
      setLoading(false);
    };
    if (eventId && token) {
      try {
        getEvent();
      } catch ({ response }) {
        const errorRedirect = getErrorRedirect(response);
        setRedirect(errorRedirect);
      }
    }
  }, [eventId, token]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : loading || loadingAuth ? (
    <div className="m-2 flex flex-wrap justify-center">
      <LoadingSkeleton />
    </div>
  ) : (
    <div className="w-full">
      <div
        className={`w-full py-24 lg:pl-48 flex flex-wrap content-center bg-cover bg-${getBackgroundBannerImage(
          event.Type
        )}`}
      >
        <div className="sm:w-full lg:w-1/3">
          <h1 className="text-white font-display text-3xl font-bold">
            {event.Activity}
          </h1>
          <h2 className="text-white font-body italic text-lg my-6">
            {dateFormat(event[DATE_AND_TIME], "d mmm, yyyy")}
          </h2>
          <h2 className="text-white font-body italic text-lg my-6">
            {event.location && Object.values(event.location).join(", ")}
          </h2>
          <h2 className="text-white font-body italic text-lg my-6">
            {event.Tagline}
          </h2>
        </div>
      </div>
      <div className="lg:pt-12 lg:pl-48">
        {event.speakers.length > 0 && (
          <h3 className="font-display text-2xl ">About the speakers</h3>
        )}
        {event.speakers.map((speaker, index) => (
          <div
            key={`${speaker.id}${index}`}
            className="w-full my-24 flex flex-wrap"
          >
            <div className="mx-2 lg:mx-0 lg:w-1/4">
              {speaker.Logo && (
                <img
                  className="min-w-full"
                  src={speaker.Logo[0].url}
                  alt="Company logo"
                />
              )}
            </div>
            <div className="w-full ml-2 lg:ml-0 lg:w-1/2 lg:pl-24 space-y-4">
              <h2 className="font-display text-2xl">{speaker.companyName}</h2>
              {speaker[SPONSOR_TOPIC]?.includes(speaker.companyName) ? null : (
                <h3 className="font-display text-lg">
                  {speaker[SPONSOR_TOPIC]}
                </h3>
              )}
              <h3 className="font-display text-lg">{speaker.Presenter}</h3>
              {speaker.companyProducts && (
                <h3 className="font-display text-lg">
                  <strong>Structure: </strong>
                  {speaker.companyProducts?.join(", ")}
                </h3>
              )}
              {speaker.companySectors && (
                <h3 className="font-display text-lg">
                  <strong>Sector: </strong>
                  {speaker.companySectors?.join(", ")}
                </h3>
              )}
              <p className="font-body">{speaker[COMPANY_INFO]}</p>

              <div className="my-4 w-full space-y-6">
                {speaker.Presentation && (
                  <a
                    className="w-24 px-8 mr-6 py-2 bg-gfpoBlue rounded text-white font-body"
                    href={speaker.Presentation}
                    download="speaker"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Download Presentation{" "}
                    <DownloadIcon className="mb-1 ml-1 h-5 w-5 inline" />
                  </a>
                )}
                {speaker[VIDEO_LINK] && (
                  <button
                    className="px-8 py-2 bg-gfpoBlue rounded text-white font-body"
                    onClick={() => {
                      setPlayingVideo({
                        ...playingVideo,
                        [speaker[VIDEO_LINK]]:
                          !playingVideo[speaker[VIDEO_LINK]],
                      });
                    }}
                  >
                    {playingVideo[speaker[VIDEO_LINK]]
                      ? `Hide Video`
                      : `Watch Video`}
                    <PlayIcon className="mb-1 ml-1 h-5 w-5 inline" />
                  </button>
                )}
                {playingVideo[speaker[VIDEO_LINK]] && (
                  <div className="w-full mt-2 h-full">
                    <span>Password: </span>
                    <input className="font-body" value="GPFO" />
                    <ReactPlayer
                      url={speaker[VIDEO_LINK]}
                      width="100%"
                      controls
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Event;
