import { Fragment, useEffect, useState } from "react";
import axios from "axios";

import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";
import getBackgroundImage from "../helpers/getBackgroundImage";
import LoadingSkeleton from "./LoadingSkeleton";
import EventCard from "./EventCard";
import getErrorRedirect from "../helpers/getErrorRedirect";
import APP_CONSTANTS from "../constants";

const { REGISTRATION_LINK, EVENT_WEBPAGE } = APP_CONSTANTS;

const RegisterInPersonButton = ({ event }) => (
  <div className="w-4/5 max-w-sm mt-6 flex justify-center text-center">
    <a
      href={event[EVENT_WEBPAGE]}
      target="_blank"
      rel="noreferrer"
      key={event.id}
      className="border-transparent w-full p-4 mt-2 bg-gfpoBlue text-white border-4 font-display text-sm py-1 px-2 rounded"
    >
      Register to attend in person
    </a>
  </div>
);

const RegisterVirtuallyLink = ({ event }) => (
  <div className="w-4/5 max-w-sm mt-6 flex justify-center text-center">
    <a
      href={event[REGISTRATION_LINK]}
      target="_blank"
      rel="noreferrer"
      key={event.id}
      className="border-transparent w-full p-4 mt-2 bg-gfpoBlue text-white border-4 font-display text-sm py-1 px-2 rounded"
    >
      Register to attend virtually
    </a>
  </div>
);

const UpcomingForums = () => {
  const { getAccessTokenSilently, loading: loadingAuth } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [upcomingForums, setUpcomingForums] = useState([]);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    const fetchUpcomingForums = async () => {
      try {
        const token = await getAccessTokenSilently();
        const {
          data: { upcomingForums },
        } = await axios.get("/api/upcoming-forums", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUpcomingForums(upcomingForums);
        setLoading(false);
      } catch ({ response }) {
        const errorRedirect = getErrorRedirect(response);
        setRedirect(errorRedirect);
      }
    };
    try {
      fetchUpcomingForums();
    } catch ({ response }) {
      const errorRedirect = getErrorRedirect(response);
      setRedirect(errorRedirect);
    }
  }, [getAccessTokenSilently]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : loading || loadingAuth ? (
    <div className="m-2 flex flex-wrap justify-center">
      {Array(3)
        .fill({})
        .map((_, index) => (
          <Fragment key={index}>
            <LoadingSkeleton />
          </Fragment>
        ))}
    </div>
  ) : (
    <div className="container mx-auto mb-12">
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-3 lg:auto-rows-fr">
        <div className="col-span-2" />
        <div className="flex justify-center w-full">
          <a
            className="border-transparent mt-2 bg-gfpoRed text-center text-white border-4 font-display text-sm py-1 px-2 rounded shadow-xl shadow-black lg:w-1/2"
            href="https://www.addevent.com/calendar/fc374108"
            target="_blank"
            rel="noreferrer"
          >
            Susbcribe to our Main Events calendar <br />
          </a>
        </div>
      </div>
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-3 lg:auto-rows-fr">
        {upcomingForums.length > 0 ? (
          upcomingForums.map((event) => (
            <div
              to={`/upcoming-forums/${event.Activity}/${event.id}`}
              className={`bg-${getBackgroundImage(
                event.Type
              )} bg-cover bg-no-repeat m-2 p-2 flex flex-wrap shadow-xl shadow-black`}
              key={event.id}
            >
              <EventCard event={event} />
              {event[EVENT_WEBPAGE] ? (
                <div className="flex flex-wrap mb-8 space-y-4 items-end justify-center w-full">
                  <RegisterInPersonButton event={event} />
                </div>
              ) : event[REGISTRATION_LINK] ? (
                <div className="flex flex-wrap mb-8 space-y-4 items-end justify-center w-full">
                  <RegisterVirtuallyLink event={event} />
                </div>
              ) : (
                event[EVENT_WEBPAGE] &&
                event[REGISTRATION_LINK] && (
                  <div className="flex flex-wrap mb-8 space-y-4 items-end justify-center">
                    <RegisterInPersonButton event={event} />
                    <RegisterVirtuallyLink event={event} />{" "}
                  </div>
                )
              )}
            </div>
          ))
        ) : (
          <>
            <div></div>
            <h1 className="font-medium font-display text-center">
              No upcoming forums
            </h1>
          </>
        )}
      </div>
    </div>
  );
};

export default UpcomingForums;
